.hero{
    width: 100%;
    height: 100vh;
    position: relative;
  
}

.hero-about{
    width: 100%;
    height: 100vh;
    position: relative;
}

img{
    width: 100%;
    /* height: 700px; */
    height: 100%;
    object-fit: cover;
}

.Hero-text{
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%,-50%);
    width: 100%;
}

.Hero-text h1{
    font-size: 3rem;
    font-weight: 800;
    background: #fff;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.Hero-text p{
    font-size: 1.3rem;
    
   color: #fff;
    padding: 0.5rem 0 2rem 0;

}

.Hero-text .show{
    text-decoration: none;
    background: #fff;
    padding: 1rem 2rem;
    border-radius: 6px;
    font-size: 1.2rem;
    font-weight: bold;
    letter-spacing: 2px;
    color: #222;
}

.carousel-item{
    width: 100%;
    height: 100vh;
    min-height: 300px;
}

.carousel-caption{
    bottom: 220px;
    z-index: 2;
    transition: opacity 0.5s ease;
    animation: fade-out 5s linear;
    animation-timeline: view();
    animation-range: exit ;
}

.carousel-caption h5{
    font-size: 45px;
    text-transform: uppercase;
    letter-spacing: 2px;
    margin-top: 25px;
}

.carousel-caption p{
    width: auto;
    margin: auto;
    font-size: 18px;
    line-height: 1.9;
}

.carousel-inner::before{
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background: rgba(0,0, 0, 0.7);
    z-index: 1;
    
}

#carouselExampleCaptions{
    overflow-y: hidden;
}

@media screen and (max-width : 555px) {
    .hero{
        width: 100%;
        height: 50vh;
        position: relative;
    }
    .carousel-inner{
        width: 100%;
        height: 50vh;
    }
    .carousel-item{
        width: 100%;
        height: 50vh;
      
        overflow-y: hidden;
    }
    .Hero-text h1{
        padding: 10px 20px;
    }

    .Hero-text p {
        font-size: 1.1rem;
        padding: 0 0 2rem 0;
    }

    .Hero-text .show{
        padding: .6rem 1.1rem;
        border-radius: 6px;
        font-size: 1rem;
        
    }
    img{
        width: 100%;
      height: 100%;
    }

    .Hero-text{
       
        top: 90%;
        left: 50%;
        transform: translate(-50%,-50%);
        width: 100%;
    }
    
    .Hero-text h1{
        font-size: 2rem;
        font-weight: 800;
        background: #fff;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }
    
    .Hero-text p{
        font-size: 1.3rem;
        
       color: #fff;
        padding: 0.5rem 0 2rem 0;
    
    }
    .carousel-caption{
        bottom: 70px;
    }
    .carousel-caption h5{
        font-size: 17px;
    }
    .carousel-caption a{
        padding: 10px 15px;
    }

    .carousel-caption p{
        width: 100%;
        
        font-size: 12px;
        line-height: 1.6;
    }

}