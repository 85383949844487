.about-container{
    margin: 3rem 2rem;
    text-align: start;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.text{
    width: 30%;
}

.phara{
    width: 100%;   
    
}

.about-container h1{
    padding-bottom: 1rem;
    text-decoration: underline;
}

.about-container p{
    padding-bottom: 2rem;
}

.circle-image {
    width: 150px;      /* Set the desired width */
    height: 150px;     /* Set the desired height (should be equal to width for a perfect circle) */
    border-radius: 50%;  /* This creates the circular shape */
    object-fit: cover;  /* Ensures the image fits well inside the circle */
    border: 2px solid #ccc;  /* Optional: Add a border for better styling */
  }

@media screen and (max-width: 850px) {
    .text{
     width: 100%;
    }
}