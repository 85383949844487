.container{
    margin: 3rem ;
     display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.container form{
     width: 670px;
    height: 500px;
    display: flex;
    justify-content: center;
    box-shadow: 5px 5px 15px rgba(0,0,0,0.5);
    border-radius: 15px;
    background: rgba(255,255,255,0.1);
    backdrop-filter: blur(10px);
    flex-wrap: wrap; 
    padding-top: 3rem;
    /* width: 50%; */
    margin: auto;
    
}


.container form input{
    width: 290px;
    height: 40px;
    padding-left: 10px;
    margin-top: 2rem;
    outline: none;
    border: none;
    font-size: 15px;
    margin-bottom: 10px;
    background: none;
    border-bottom: 2px solid #000000;
}

.container form input::placeholder{
    color: #000000;
}

.container form #lastName,
.container form #mobile{
    margin-left: 20px;
}

.container form h4{
    color: #000000;
    font-weight: 300;
    width: 600px;
    margin-top: 20px;
}

.container form textarea{
    background: none;
    border: none;
    border-bottom: 2px solid #000000;
    color: #000000;
    font-weight: 200;
    font-size: 15px;
    padding: 10px;
   
    min-width: 600px;
    max-width: 600px;
    min-height: 80px;
    max-height: 80px;
}

textarea::-webkit-scrollbar{
    width: 1em;
}

textarea::-webkit-scrollbar-thumb{
    background-color: rgba(194,194,194,0.713);
}

.container form #button{
    /* border: px; */
    background: #e0e0e0;
    border-radius: 5px;
    margin-top: 20px;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
    width: 100px;
    padding: 0;
    margin-bottom: 30px;
    transition: 0.3s;
}

.container form #button:hover{
    /* opacity: 0.7; */
    background-color: #092938;
    color: #fff;
}


@media screen and (max-width:850px){
    .container{
        margin: 6rem 2rem ;
        display: contents;
    }
    .container form{
     
        height: 100%;
        
        width: 90%; 
        margin-bottom: 5rem;
    }
    .container form #button{
        margin-right: 10px;
    }
    .container form textarea{
    min-width: 300px;
    }
    
    .load-more-button{
        align-items: center;
    }
}