
  .service-card {
    display: flex;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-top: 2rem;
  }
  
  .service-text {
    flex: 1;
    width: 30%;
    font-size: 16px;
    line-height: 1.5;
    padding-right: 1rem;
    
  }

  .service-text p{
    padding: 0 5rem ;
    text-align: justify;
  }

  .service-reverse .service-text{
    padding-left: 1rem;
  }
  
  .service-image img {
    max-width: 850px;
    height: 500px;
    margin-left: auto;
  }

.service-details{
    margin: 6rem auto;
    padding: 20px;
}

.service-details h1{
    font-size: 3rem;
}

.service-des{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.service-reverse{
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin: 4.5rem 0 4.5rem 0;
}

.des-text{
    width: 45%;
    text-align: start;
    font-size: 1rem;
    color: #000000;
    text-decoration: none;

}

.des-text p{
    color: #000000;
}  

.des-text h2{
    padding-bottom: 1rem;
    text-decoration: none;
}

.image{
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    z-index: -99;
}


.image img:nth-child(2){
    position: absolute;
    top:-10%;
    right: 0; 
}

@media screen and (max-width:850px) {
    .service-details{
        margin: 4rem 2rem;
        /* display: contents; */
    }

    .service-des{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .service-heading{
        padding: 1rem;
    }
   
    .service-reverse{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .service-text{
        width: 100%; 
    }

    .service-text p{
        padding: 0;
      }

    .service-image{
        width: 100%;
        margin: 0;
        height: 250px;
    }

    .service-image img{
        height: 250px;
    }
}