@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:ital,wght@0,300;1,400;1,300&display=swap');

  /* @import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@600&family=Montserrat:wght@400;700&family=Poppins:ital,wght@0,100;1,100;1,300&display=swap'); */


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  text-align: center;

}
body{
  overflow-x: hidden;
}
