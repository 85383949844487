.work{
    margin: 4rem 4rem;
    color: #2a2a2a;
}

.work h1{
    font-size: 3rem;
   
}

.workcard{
  margin-top: 3rem;
  display: flex;
  flex-wrap: wrap;
}



.w-card-wrapper {
    width: 32%;
    margin-bottom: 3rem;
    margin-right: 1rem;
  }

.w-card{
    width: 100%;
    text-align: start;
    box-shadow: 0 5px 25px 2px rgba(0, 0, 0, 0.11);
    border-radius: 7px;
    cursor: pointer;
    padding: 1rem .8rem;
   
}

.w-image{
    height: 200px;
    overflow: hidden;
    border-radius: 7px;
}

.w-image img{
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    border-radius: 7px;
}

.w-image:hover img{
   transform: scale(1.3);
}

.w-card h4{
    font-size: 1.3rem;
    padding: .9rem 0 .2rem 0;
    color: #000000;
}

.w-card p{
    color: #000000;
}

.load-more-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  .load-more-button:hover button{
    cursor: pointer;
    background: #092938;
  }
  .show-more,
  .show-less {
    margin: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: 14px;
  }


/* @media screen and (max-width:850px){
    
    .work{
        margin: 4rem 2rem;
    }
    .workcard{
        flex-direction: column;
        display: contents;
    }

    .w-card{
        width: 300%;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }

    .w-image{
        width: 250px;
        margin: auto;
    }
} */

@media screen and (max-width:850px) {

    .work{
        margin: 4rem 2rem;
    }
    .workcard{
        flex-direction: column;
        display: contents;
    }

    .w-card{
        width: 300%;
        margin-bottom: 1.5rem;
        margin-top: 1.5rem;
    }

    .w-image{
        width: 250px;
        margin: auto;
    }

    .product-item{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .product-heading{
        padding: 1rem;
    }
  
    .product-text{
        width: 100%; 
    }

    .product-text p{
        padding: 0;
      }

    .product-image{
        width: 100%;
        margin: 0;
        height: 250px;
    }
    .button-container{
        display: flex;
        align-items: center;
        margin: 0;
    }
    .button-container button{
      margin-top: 82rem;
    }
  
    button{
        display: block;
    }
      .show-more,
      .show-less {
        width: 100%;
    
      }
}