
   .product-details {
    margin: auto;
    padding: 20px;
  }
  
  .product-card {
    display: flex;
    align-items: center;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }

  .product-item{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .product-text {
    flex: 1;
    width: 30%;
    font-size: 16px;
    line-height: 1.5;
    padding-right: 1rem;
  }
  
  .product-image {
    max-width: 600px;
    height: 500px;
    margin-left: auto;
  }
  
  .product-images-row {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    flex-wrap: wrap;
    padding: 1rem;
  }
  
  .product-image-small {
    max-width: 450px;
    height: auto;
    margin: 0 10px;
    padding: auto;
  }
  
  .image-slider {
    display: flex;
    overflow: hidden;
    white-space: nowrap;
    position: relative;
  }
  
  .image-slider-container {
    /* display: flex; */
    transition: transform 0.3s ease-in-out;
  }
  
  .image-slider-container img {
    height: auto;
    width: 100%;
    padding: 0 10px;
  }

  .button-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
  }
  
  .button-container button {
    all: unset;
    display: block;
    position: relative;
    padding: 1rem;
    cursor: pointer;
    width: 20%; /* Set the width as a percentage of the parent container */
    max-width: 50px; /* Optional: Add a max-width to limit button size on smaller screens */
  }
  
  .button-container button > * {
    stroke: black;
    fill: black;
    width: 2rem;
    height: 2rem;
  }

  button {
    margin: 10px;
   
  }

  @media screen and (max-width:850px) {
    .product-details {
      margin: 0;
    }

    .product-item{
        display: flex;
        flex-direction: column-reverse;
        margin-top: 2.5rem;
    }

    .product-heading{
        padding: 1rem;
    }
  
    .product-text{
        width: 100%; 
    }

    .product-text p{
        padding: 0;
      }

    .product-image{
        width: 100%;
        margin: 0;
        height: 250px;
    }
    .button-container {
      display: flex;
      align-items: center;
      justify-content: center; /* Center the buttons on smaller screens */
      margin: 0;
    }
  
    .button-container button {
      width: 20%; /* Adjust the button width for smaller screens */
    }
  
}