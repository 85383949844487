
/* Footer.css */
.footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 4rem 6rem;
    background: #092938;
    color: #fff;
  }
  
  .left,
  .middle,
  .right {
    flex: 1;
  }
  
  .logo-img {
    width: 100px;
    height: 100px;
    border-radius: 10%;
  }
  
  .middle h1,
  .middle h4,
  .middle .social {
    margin-bottom: 1rem;
    font-family: 'comfortaa',sans-serif;
  }


  .middle {
    text-align: center; /* Center the content */
  }
  .social {
    display: flex;
    justify-content: center;
  }
  
  .social a {
    margin-right: 1rem;
    color: #fff;
    margin-left: 2rem;
  }
  .social a:hover{
    color: #01959a;
}
  .right h4 {
    margin-top: 1rem;
    font-family: 'comfortaa',sans-serif;
  }
  
  @media screen and (max-width: 850px) {
    .footer {
      flex-direction: column;
      text-align: center;
      padding: 4rem 2rem;
    }
  
    .left,
    .middle,
    .right {
      width: 100%;
    }
  
    .right h4 {
      margin-top: 0.5rem;
    }
    .social {
        display: flex;
        justify-content: center;
      }
  }
  